import React, { Suspense, lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  matchPath,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import {
  Analytics,
  fetchToolInfo,
  fetchToolsConfig,
  fetchFeatureFlag,
  useAuth,
  ProtectedRoute,
  LogoutPageView,
  fetchRoles,
  fetchContentRepositories,
  userRolesMap,
  fetchSystemProfile,
  sharedRoutes,
  fetchCustomAttributes,
} from '@clatter/platform';
import { createGlobalStyle } from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  Loader,
  appGlobalStyles,
  AppThemeProvider,
  SsoCallbackPage,
  TrackPageviewEvent,
} from '@clatter/ui';
import routes from './routes';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

const LandingPageView = lazy(() =>
  import('./pages/LandingPage/LandingPageView'),
);
const DashboardView = lazy(() => import('./pages/Dashboard/DashboardView'));
const UsersListView = lazy(() => import('./pages/Users/UsersListView'));
const UserFormView = lazy(() => import('./pages/Users/UserFormView'));
const UserCustomAttributesView = lazy(() =>
  import('./pages/Users/UserCustomAttributesView'),
);
const UsersLoginsReportView = lazy(() =>
  import('./pages/Reports/UsersLogins/UsersLoginsReportView'),
);
const UsersLoginsDetailsReportView = lazy(() =>
  import('./pages/Reports/UsersLogins/UsersLoginsDetailsReportView'),
);
const FeatureFlagView = lazy(() =>
  import('./pages/FeatureFlag/FeatureFlagView'),
);
const SystemConfigurationView = lazy(() =>
  import('./pages/SystemConfiguration/SystemConfigurationView'),
);
const ActiveUsersReportView = lazy(() =>
  import('./pages/Reports/ActiveUsers/ActiveUsersReportView'),
);
const FailedLoginsReportView = lazy(() =>
  import('./pages/Reports/FailedLogins/FailedLoginsReportView'),
);
const FailedLoginsDetailsReportView = lazy(() =>
  import('./pages/Reports/FailedLogins/FailedLoginsDetailsReportView'),
);
const PageViewsReportView = lazy(() =>
  import('./pages/Reports/PageViews/PageViewsReportView'),
);
const UnauthorizedUserAccessAttemptsReportView = lazy(() =>
  import('./pages/Reports/UnauthorizedUserAccessAttempts/UnauthorizedUserAccessAttemptsReportView'),
);
const UnauthorizedUserAccessAttemptsDetailsReportView = lazy(() =>
  import('./pages/Reports/UnauthorizedUserAccessAttempts/UnauthorizedUserAccessAttemptsDetailsReportView'),
);
const SlidesDownloadsReportView = lazy(() =>
  import('./pages/Reports/SlidesDownloads/SlidesDownloadsReportView'),
);
const AnalyticsView = lazy(() =>
  import('./pages/Reports/Analytics/AnalyticsView'),
);
const AssetUsageToolListView = lazy(() =>
  import('./pages/Reports/AssetUsage/AssetUsageToolListView'),
);
const AssetUsageToolDetailsView = lazy(() =>
  import('./pages/Reports/AssetUsage/AssetUsageToolDetailsView'),
);
const AssetUsageAssetListView = lazy(() =>
  import('./pages/Reports/AssetUsage/AssetUsageAssetListView'),
);
const AssetUsageAssetDetailsView = lazy(() =>
  import('./pages/Reports/AssetUsage/AssetUsageAssetDetailsView'),
);
const UtilizationDashboard = lazy(() =>
  import('./pages/Reports/UtilizationDashboard/UtilizationDashboard'),
);

export function App() {
  const GlobalStyle = createGlobalStyle`
    ${appGlobalStyles};
  `;

  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();
  const location = useLocation();
  const isOnLogoutPage = !!matchPath(location.pathname, {
    path: routes.logout,
    exact: true,
  });
  const isOnSsoCallbackPage = !!matchPath(location.pathname, {
    path: sharedRoutes.ssoCallback,
    exact: true,
  });

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchRoles());
      dispatch(fetchSystemProfile());
      dispatch(fetchContentRepositories());
      dispatch(fetchCustomAttributes());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <Router>
        <TrackPageviewEvent />
        <Analytics />

        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={routes.index} component={LandingPageView} />
            <Route exact path={routes.logout} component={LogoutPageView} />
            <Route
              exact
              path={sharedRoutes.ssoCallback}
              component={SsoCallbackPage}
            />
            <ProtectedRoute
              exact
              path={routes.userCustomAttributes}
              component={UserCustomAttributesView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.users}
              component={UsersListView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.addUser}
              component={UserFormView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.editUser}
              component={UserFormView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.systemConfiguration}
              component={SystemConfigurationView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.featureFlags}
              component={FeatureFlagView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.dashboard}
              component={DashboardView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.analytics}
              component={AnalyticsView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportUtilizationDashboard}
              component={UtilizationDashboard}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportActiveUsers}
              component={ActiveUsersReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportFailedLogins}
              component={FailedLoginsReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportFailedLoginsDetails}
              component={FailedLoginsDetailsReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportPageViews}
              component={PageViewsReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportUnauthorizedUserAccessAttempts}
              component={UnauthorizedUserAccessAttemptsReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportUnauthorizedUserAccessAttemptsDetails}
              component={UnauthorizedUserAccessAttemptsDetailsReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportSlidesDownloads}
              component={SlidesDownloadsReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={[routes.reportAssetUsage, routes.reportUnusedAsset]}
              component={AssetUsageToolListView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={[routes.reportAssetUsageToolDetails, routes.reportUnusedAssetToolDetails]}
              component={AssetUsageToolDetailsView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={[routes.reportAssetUsageToolAssetDetails, routes.reportUnusedAssetToolAssetDetails]}
              component={AssetUsageAssetListView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={[routes.reportAssetUsageAssetDetails, routes.reportUnusedAssetAssetDetails]}
              component={AssetUsageAssetDetailsView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reportUsersLoginsDetails}
              component={UsersLoginsDetailsReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.reports}
              component={UsersLoginsReportView}
              accessRoles={userRolesMap.clatterAdmin}
            />
          </Switch>
        </Suspense>
      </Router>
    </AppThemeProvider>
  );
}
export default App;
