import React, { useState } from 'react';
import { StyledControlsContainer } from './components/StyledControlsContainer';
import { AssetUsageGalleryView } from './components/AssetUsageGalleryView';
import { AssetUsageFilters } from './components/AssetUsageFilters';
import {
  ViewToggle,
  ZoomControls,
  useDeepCompareMemo,
  assetUsageViewTypes,
  fieldsToFilterBy,
} from '@clatter/platform';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { AssetUsageListView } from './components/AssetUsageListView';
import { CircularLoader } from "@clatter/ui";

// For now this method is only supporting 'search_query' filter prop
// to be extended when needed...
const getFilteredReportDataByAssetType = ({ item, filterValues, searchableFields }) => {
  const dataToFilterOn = [];
  const fieldsToSearch = searchableFields || fieldsToFilterBy?.[item.type];

  if (!fieldsToSearch) {
    console.error('getFilteredReportDataByAssetType::: No "fieldsToSearch provided! Unable to filter data!');
    return false;
  }

  Object.keys(item || {}).forEach((itemKey) => {
    if ((fieldsToSearch || []).includes(itemKey)) {
      // let's exclude fields that are not a string or number
      if (item[itemKey]?.substring || item[itemKey]?.toFixed) {
        dataToFilterOn.push(isNaN(item[itemKey]) ? item[itemKey]?.toLowerCase() : item[itemKey]);
      }
    }
  });

  return dataToFilterOn?.join(' ').includes(filterValues?.search_query?.toLowerCase());
};

export const AssetUsage = ({
  defaultActiveView = assetUsageViewTypes.LIST,
  defaultSortField,
  disableZoom = false,
  documentFileName,
  reportData,
  listViewColumns,
  isLoading,
  listViewTableName,
  listViewExportName,
  searchableFields,
  assetType = 'image',
  showOnlyListView = false,
}) => {
  const [activeView, setActiveView] = useState(showOnlyListView ? assetUsageViewTypes.LIST : defaultActiveView);
  const [zoomValue, setZoomValue] = useState(40);
  const [filterValues, setFilterValues] = useState(null);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      search_query: '',
    },
  });

  //region Computed
  const filteredReportData = useDeepCompareMemo(() => {
    if (filterValues?.search_query?.trim()?.length) {
      return [...reportData].filter((item) =>
        getFilteredReportDataByAssetType({ item, filterValues, searchableFields }),
      );
    }
    return reportData;
  }, [reportData, searchableFields, filterValues?.search_query]);
  //endregion

  return (
    <>
      <FormProvider {...formMethods}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          gap={2}
          marginBottom={4}
          alignItems="center"
          justifyContent={'space-between'}
        >
          <AssetUsageFilters disabled={isLoading || reportData?.length <= 1} onFiltersChange={setFilterValues} />
          <StyledControlsContainer>
            {!disableZoom && activeView === assetUsageViewTypes.THUMBNAIL && (
              <ZoomControls value={zoomValue} onChange={setZoomValue} step={20} />
            )}
            {!showOnlyListView && <ViewToggle activeView={activeView} onChange={setActiveView} />}
          </StyledControlsContainer>
        </Stack>

        {activeView === assetUsageViewTypes.THUMBNAIL && (
          <>
            { isLoading
                ? <Stack justifyContent="center" alignItems="center" style={{height: '100%', minHeight: '40vh'}}>
                    <CircularLoader />
                  </Stack>
                : <AssetUsageGalleryView reportData={filteredReportData} zoomValue={zoomValue} assetType={assetType} />
            }
          </>
        )}

        {activeView === assetUsageViewTypes.LIST && (
          <AssetUsageListView
            columns={listViewColumns}
            reportData={filteredReportData}
            documentFileName={documentFileName}
            defaultSortField={defaultSortField}
            isLoading={isLoading}
            listViewTableName={listViewTableName}
            listViewExportName={listViewExportName}
          />
        )}
      </FormProvider>
    </>
  );
};
