import { configureStore } from '@reduxjs/toolkit';
import {
  PLATFORM_FEATURE_KEY,
  globalErrorHandlerMiddleware,
  platformReducers,
} from '@clatter/platform';
import { USERS_FEATURE_KEY, usersReducer } from './users.slice';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";

export const storeConfig = {
  reducer: {
    [PLATFORM_FEATURE_KEY]: platformReducers,
    [USERS_FEATURE_KEY]: usersReducer,
  },
  // Additional middleware can be passed to this array
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(globalErrorHandlerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
  // Optional Redux store enhancers
  enhancers: [],
};

export const store = configureStore(storeConfig);
