import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/App';
import { AuthState, initTagManager, initSentry, updateFavicon } from '@clatter/platform';
import { store } from './store/index';
import { LicenseInfo } from '@mui/x-license-pro';
import { Loader } from "@clatter/ui";
import { persistStore } from "redux-persist";

LicenseInfo.setLicenseKey(process.env.NX_MUI_LICENSE_KEY);

updateFavicon();
initTagManager();
initSentry();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistStore(store)}>
      <StrictMode>
        <BrowserRouter>
          <AuthState>
            <App />
          </AuthState>
        </BrowserRouter>
      </StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
